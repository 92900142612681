* {
  box-sizing: border-box;
}

*:not(.nav-bar, .welcome-slider) {
  transition: 150ms all linear;
}

html, body, #root {
  height: 100%;
}

html {
  overflow-x: hidden;
}

button, button:focus {
  outline: 0;
}

.container {
  max-width: 37.25rem;
  padding-top: 100px;
}

.relative {
  position: relative;
}

.bg-black {
  background: #000;
  background-color: #000;
}

.fixed-width {
  max-width: 37.25rem;
}

.big {
  font-size: 75px;
}

.hidden {
  display: none !important;
}

.m0auto {
  margin: 0 auto;
}

/* index */
.wizard-container {
  background-color: #000;
  padding-top: 75px;
  padding-bottom: 25px;
  height: 100%;
}

.coin-entry {
  padding: 15px;
}

.wizard-container [class^="InputField__LabelWrapper"] p {
  font-size: .8rem;
  line-height: unset !important;
  color: #fff;
}

.wizard-container img {
  width: 100%;
}

.wizard-container div[class^="InputText__InputTextWrapper"] p {
  color: #fff;
}

.wizard-container ul[class^="UnorderedList__StyledUl"] {
  color: #FFFFFF;
}

.wizard-container button {
  background: #fff;
  color: #000;
}

.welcome-slider-wrapper {
  position: absolute;
  height: 200px;
  background: #000;
  left: 0;
  right: 0;
}

.welcome-slider {
  left: 0;
  right: 0;
  background: #000;
}

/* Nav bar */
.nav-bar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 75px;
}

.nav-contents {
  max-width: 1272px;
  margin: 0 auto;
}

.nav-expanded {
  height: 100%;
  background: #fff;
  z-index: 100;
}

.nav-logo img {
  max-height: 75px;
  padding: 10px;
}

.nav-button img {
  height: 45px;
  width: 45px;
}

.nav-button {
  background: transparent;
  color: #fff;
  border: 0px;
  background: #000;
}

.nav-open, .nav-items, .nav-items * {
  background: #fff;
  z-index: 10000000;
}

.nav-bar [class^="Typography__StyledTypography"] {
  font-size: 25px;
}

.nav-open .nav-close, .nav-hamburger {
  font-size: 30px;
}

.nav-open .nav-items {
  padding: 32.5px;
  max-width: 1272px;
  margin: 0 auto;
}

#root a:hover {
  text-decoration: none;
}

/* photo uploader */
.photo-uploader {
  width: 100%;
  height: 300px;
  background-image: linear-gradient(to right, #000 50%, white 50%);
  background-position: top;
  background-size: 10px 0px;
  background-repeat: repeat-x;
  border-color: #000;
  border-width: 1px;
  border-style: dashed;
}

.video-overlay {
  border-color: #00ac3e;
  border-width: 2px;
  border-style: solid;
}

.upload-icon {
  max-width: 100px;
}

.dropzone {
  width: 100%;
  text-align: center;
}

/* file upload */
.file-upload-footer a, .file-upload-footer button {
  min-width: 130px;
}

.file-upload-footer a span, .file-upload-footer button span {
  margin: 0 auto;
}

/* help form */
.help-form textarea {
  width: 100%;
}

.help-form [class^="InputField__StyledContainer"] {
  margin-top: 1.5rem!important;
}

/* ------------ */
/* error message */
#root [class^="Notification__AlertWrapper"] {
  margin-bottom: 15px;
}

#root button[class^="NotificationClose__StyledButton"] {
  background: transparent;
  font-family: BrandFont-Display;
  font-weight: 750;
}

#root div[class^="Notification__ContentWrapper"] {
  font-family: BrandFont-Display;
  font-weight: 750;
}

#error-bar {
  width: 100%;
}

.error-text {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 0.75rem;
  font-family: BrandFont-Text;
  color: #000000;
  margin: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.text-area {
  background-color: #ffffff;
  border-bottom: 0.0625rem solid #000000;
  border-left: 0.0625rem solid #d8dada;
  border-radius: 0;
  border-right: 0.0625rem solid #d8dada;
  border-top: 0.0625rem solid #d8dada;
  box-sizing: border-box;
  color: #000000;
  display: inline-block;
  font-family: BrandFont-Text;
  font-size: 16px;
  line-height: 1.05rem;
  margin: 0;
  max-width: 37.25rem;
  padding: 1rem;
  position: relative;
  vertical-align: top;
  width: inherit;
  color: #000000;
  padding-bottom: 0.75rem;
}

.text-area-error {
  border-color: #ed7000;
  border-bottom-width: 0.25rem;
}

.InputField__StyledInput-sc-7wo79w-0 {
  font-size: 16px !important;
}

.pr-12 {
  padding-right: 3rem;
}

.transfer-card {
  border: 1px solid lightgray;
  height: 66px;
  max-width: 350px;
  border-radius: 4px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transfer-count-container {
  margin: 0.6rem;
  vertical-align: center;
  background-color: lightgray;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.left-side {
  display: flex;
  justify-content: center;
  align-items: center;
}

.date {
  margin-right: 0.6rem;
}

div[class^="TitleAccented__LineWrapper"] {
  width: 100%;
}

div[class^="TitleAccented__LineWrapper"]:first-of-type {
  width: 0%
}

#register-btn {
  color: white;
  background-color: transparent;
  border: 0.0625rem solid #FFFFFF;
}

[class^="InputField__LabelWrapper"] p {
  font-size: .8rem;
  line-height: unset;
  color: #000;
}

/* buttons  on mobile */
@media  screen and (max-width: 768px) {
  .w-100-mobile {
    width: 100% !important;
  }

  #root .Notification-close-button {
    width: inherit !important;
  }

  .wizard-container button:not([class^="NotificationClose__StyledButton"]) {
    width: 100%;
  }

  .welcome-slider {
    left: 0;
    right: 0;
    padding: 0px 15px;
  }
}
